import React, {useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {CampaignData, transformJsonToCampaignDataList} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {useLanguage} from "../../contexts/LanguageContext";
import {ApexLineChart, ChartLineData, ItemLineData} from "../../components/shared/apex-chart";
import {getDateByLocale} from "../../lib/utils";
import {useAuth} from "../../contexts/AuthContext";

type InstallMonitorProps = {
    onChangeCampaign: (campaign: CampaignData) => void
}

export const InstallMonitor: React.FC<InstallMonitorProps> = ({onChangeCampaign}) => {

    const loadingDataInit = {
        init: false,
        refresh: false
    }
    const axiosPrivate = useAxiosPrivate();
    const {translate, language} = useLanguage();
    const {user} = useAuth();

    const [loading, setLoading] = useState(loadingDataInit);

    const chartTypes = ["ALL", "CURRENT_DAY"];

    const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
    const [campaign, setCampaign] = useState<CampaignData | null>(null);
    const [chartType, setChartType] = useState<string>(chartTypes[0]);

    const [monitoringData, setMonitoringData] = useState<ChartLineData>({categories: [], series: []});
    const [lastUpdateTime, setLastUpdateTime] = useState<string | null>(null);

    const loadCampaigns = () => {
        axiosPrivate.get(`/campaigns/list${user?.roles.some(r => r === 'ADMIN') ? '/all' : ''}?status=IN_PROGRESS&search=IN_PROGRESS`).then((response) => {
            if (response.status === 200 && response.data.data) {
                let campaignData = transformJsonToCampaignDataList(response.data.data);
                setCampaigns(campaignData);
            }
            setLoading({
                ...loading,
                init: false
            });
        }).catch((error) => {
            console.log(error);
            setLoading({
                ...loading,
                init: false
            });
        });
    }

    const handleRefresh = () => {
        setLoading({
            ...loading,
            refresh: true
        });

        //TODO request refresh data if error show toast

        setTimeout(() => {
            setLoading({
                ...loading,
                refresh: false
            });
        }, 2500);
    }

    function millisecondsToDateStrWithHour(milliseconds: number): string {
        const date = new Date(milliseconds);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');

        if (chartType === 'ALL') {
            return `${day}.${month}`;
        }

        return `${day}.${month} ${hours}:00`;
    }

    const loadMonitoring = () => {

        setMonitoringData({categories: [], series: []});

        setLoading(
            {
                ...loading,
                refresh: true
            }
        );

        axiosPrivate.post(`/statistic/campaign`, {
            cuid: campaign?.id,
            type: chartType,
        }).then((response) => {
            if (response.status === 200 && response.data.data) {

                if (Object.keys(response.data.data.data_monitoring).length > 0) {
                    let responseData = response.data.data;

                    let dataMonitoring = responseData.data_monitoring;
                    const result: ChartLineData = {categories: [], series: []};

                    result.categories = responseData.time_labels.map((item: any) => millisecondsToDateStrWithHour(item));

                    let totalSumData: number[] = Array(result.categories.length).fill(0);

                    Object.keys(dataMonitoring).forEach((key) => {

                        const typeKey = key.split("$$")[0];
                        const keyword = key.split("$$")[1];

                        let newKey = typeKey + (keyword && keyword !== "" ? " - " + keyword : "");

                        let md: ItemLineData = {name: newKey, data: []};
                        result.categories.forEach((item, index) => {
                            let valueForCurrentTime = 0;
                            dataMonitoring[key].forEach((v: any) => {
                                if (item === millisecondsToDateStrWithHour(v.time_check)) {
                                    valueForCurrentTime = v.value;
                                }
                            });
                            md.data.push(valueForCurrentTime);
                            totalSumData[index] += valueForCurrentTime; // Добавляем значение к общей сумме для соответствующей временной метки
                        });
                        result.series.push(md);
                    });

                    result.series.push({name: 'Total', data: totalSumData});

                    setLastUpdateTime(responseData.time_update);
                    setMonitoringData(result);
                } else {
                    setMonitoringData({categories: [], series: []});
                }

                setLoading(
                    {
                        ...loading,
                        refresh: false
                    }
                );
            } else {
                setLoading(
                    {
                        ...loading,
                        refresh: false
                    }
                );
            }
        }).catch((error) => {
            console.log(error);
            setLoading(
                {
                    ...loading,
                    refresh: false
                }
            );
        });
    }

    useEffect(() => {
        if (campaign) {
            loadMonitoring();
        }
    }, [campaign, chartType]);

    useEffect(() => {
        onChangeCampaign && campaign && onChangeCampaign(campaign);
    }, [campaign]);

    useEffect(() => {
        loadCampaigns();

        return () => {

        }
    }, []);

    return (
        <Card>
            <div className={"grid grid-cols-3 w-full items-center"}>
                <div className={"flex justify-start"}>
                    <span className={"font-bold text-xl text-gray-dark dark:text-white"}>{translate('dashboard.statistic.title')}</span>
                </div>

                <div className={"flex flex-row justify-center items-center gap-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <div className={"flex flex-row items-center gap-2"}>
                            <div className={"w-72"}>
                                <Select
                                    disabled={loading.init || loading.refresh}
                                    onValueChange={(value) => setCampaign(campaigns.find(c => c.id === value) || null)}
                                >
                                    <SelectTrigger
                                        className={`h-16 bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                                        <SelectValue placeholder="Select Campaign"/>
                                    </SelectTrigger>

                                    <SelectContent className={"flex flex-col"}>
                                        <div className={"h-fit max-h-60 overflow-scroll p-1"}>
                                            {campaigns.length === 0 && (
                                                <span className={"flex text-xs text-gray-dark dark:text-white font-bold items-center justify-center"}>
                                                    {translate('campaigns.my.no-found-campaigns')}
                                                </span>
                                            )}
                                            {campaigns.map((campaign) => (
                                                <SelectItem

                                                    key={campaign.id}
                                                    value={campaign.id!!}
                                                    className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                >
                                                    <div className={"flex flex-row gap-2"}>
                                                        <Avatar className={`h-12 w-12 transition-all`}>
                                                            <AvatarFallback delayMs={500}>
                                                                I
                                                            </AvatarFallback>
                                                            <AvatarImage
                                                                src={campaign.app.details[campaign.locale.country + '_' + campaign.locale.language].image_src}
                                                            />
                                                        </Avatar>
                                                        <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>
                                                                {campaign.app.details[campaign.locale.country + '_' + campaign.locale.language].name}
                                                            </span>
                                                            <span
                                                                className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                {campaign.app.details[campaign.locale.country + '_' + campaign.locale.language] ?
                                                                    campaign.app.details[campaign.locale.country + '_' + campaign.locale.language].publisher :
                                                                    campaign.app.details['us_en'].publisher}
                                                            </span>
                                                            {campaign.user?.username !== user?.username && (
                                                                <span
                                                                    className={"text-xs text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                    {campaign.user?.username}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </div>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={"w-52"}>
                        <Select
                            disabled={loading.init || loading.refresh}
                            onValueChange={(value) => setChartType(value)}
                            defaultValue={chartTypes[0]}
                        >
                            <SelectTrigger
                                className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-12">
                                <SelectValue placeholder="Chart type"/>
                            </SelectTrigger>

                            <SelectContent>
                                {Object.values(chartTypes).map((type) => (
                                    <SelectItem
                                        key={type}
                                        value={type}
                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                    >
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className={"flex flex-row items-center gap-1 justify-end"}>
                    {loading.init || loading.refresh ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={"Last update time"}>
                            <span
                                className={"text-xs text-gray/50"}>{getDateByLocale(lastUpdateTime, language) === '' ? translate('def.no-data') : getDateByLocale(lastUpdateTime, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                            onClick={handleRefresh}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.init || loading.refresh ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <ApexLineChart data={monitoringData} loading={loading.refresh}/>
        </Card>
    )
}
