import Chart from "react-apexcharts";
import React, {useEffect, useState} from "react";
import {Loader} from "./loader";
import {ApexOptions} from "apexcharts";
import {useTheme} from "../../contexts/ThemeContext";
import {useLanguage} from "../../contexts/LanguageContext";

export type ChartLineData = {
    categories: string[],
    series: ItemLineData[]
}

export type ItemLineData = {
    name: string
    data: (number | null)[]
}

type ApexLineChartProps = {
    loading?: boolean
    data: ChartLineData,
    invertY?: boolean
    type?: "line" | "area"
    min?: number
    max?: number
}

const ApexLineChart: React.FC<ApexLineChartProps> = (
    {
        loading = false,
        data,
        invertY = false,
        type = 'area',
        min = 0,
        max
    }
) => {

    const {mode} = useTheme();

    const setMax = () => {
        let computeMax = Math.max(
            ...data.series.flatMap(item => item.data.filter((i): i is number => i !== null))
        );

        computeMax = computeMax + (computeMax * 0.3);

        if (computeMax < min) {
            computeMax = min + 5;
        }

        if (max && computeMax < max) {
            computeMax = max;
        }

        return computeMax;
    }


    const [options, setOptions] = useState<ApexOptions>({
        stroke: {
            curve: 'smooth'
        },
        chart: {
            background: 'transparent',
            type: type,
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
            toolbar: {
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            categories: data.categories,
            crosshairs: {
                show: true,
                stroke: {
                    dashArray: 2,
                },
            }
        },
        grid: {
            show: true,
            borderColor: '#444',
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        yaxis: {
            min: min,
            max: setMax(),
            reversed: invertY,
            decimalsInFloat: 0,
            labels: {
                formatter: (value, _) => {
                    return value?.toFixed(0);
                }
            }
        },
        legend: {
            horizontalAlign: 'left',
            show: true,
            showForSingleSeries: true,
            itemMargin: {
                horizontal: 12
            },
            labels: {
                useSeriesColors: true
            },
            markers: {
                offsetX: -5,
                strokeWidth: 2
            }
        },
        theme: {
            mode: mode,
        }
    });


    useEffect(() => {
        const chartContainer = document.querySelector('#sidebar');
        const resizeObserver = new ResizeObserver(() => {
            setOptions((prevOptions) => ({
                ...prevOptions,
                theme: {
                    mode: mode,
                },
            }));
        });

        if (chartContainer) {
            resizeObserver.observe(chartContainer);
        }

        return () => {
            if (chartContainer) {
                resizeObserver.unobserve(chartContainer);
            }
        };
    }, []);


    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            theme: {
                mode: mode,
            },
            xaxis: {
                categories: data.categories,
                crosshairs: {
                    show: true,
                    stroke: {
                        dashArray: 2,
                    },
                }
            },
            yaxis: {
                min: min,
                max: setMax(),
                reversed: invertY,
                decimalsInFloat: 0,
                labels: {
                    formatter: (value, _) => {
                        return value?.toFixed(0);
                    }
                }
            },
        }));
    }, [mode, data.categories, data.series]);

    return (
        <div className="flex justify-center items-center w-full h-128 relative">
            {loading && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <Loader className={"z-10"}/>
                </div>
            )}
            <div className={"relative w-full h-full rounded-md p-1"}>
                <Chart
                    width={"100%"}
                    height={"100%"}
                    type={type}
                    series={data.series}
                    options={options}
                />
            </div>
        </div>
    )
}

export type ChartDonutData = {
    labels: string[],
    series: number[],
    total?: number
}

type ApexDonutChartProps = {
    data: ChartDonutData
}

const ApexDonutChart: React.FC<ApexDonutChartProps> = (
    {
        data,
    }
) => {

    const {mode} = useTheme();
    const {translate} = useLanguage();

    const isAllZero = data.series.every(value => value === 0);

    const options: ApexOptions = {
        chart: {
            background: 'transparent',
            type: 'donut'
        },
        dataLabels: {enabled: true},
        tooltip: {enabled: false},
        colors: isAllZero ? (["#777777", "#777777", "#777777"]) : (["#039e46", "#F39C12", "#777777"]),
        stroke: {width: 0},
        labels: data.labels,
        legend: {
            position: 'bottom',
            fontSize: '10px',
            show: true,
            showForSingleSeries: true,
            labels: {
                useSeriesColors: true
            },
            markers: {
                offsetX: -1,
                strokeWidth: 1
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: "10%",
                    labels: {
                        show: !isAllZero,
                        name: {show: false},
                        total: {
                            show: !isAllZero,
                            showAlways: false
                        }
                    }
                }
            }
        },

        theme: {
            mode: mode,
        }
    };

    return (
        <div className="flex justify-center items-center w-96 h-fit min-h-48">
            <Chart
                width={"100%"}
                height={"100%"}
                type={"donut"}
                series={isAllZero ? [1] : data.series}
                options={options}
            />
        </div>
    )
}

export {ApexLineChart, ApexDonutChart}
