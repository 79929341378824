import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {Button} from "../../components/shared/button";
import {useModal} from "../../hooks/use-modal-store";
import {AccountData, AccountDataList, transformJsonToAccountDataListCount} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Input} from "../../components/shared/Input";
import {ChevronRight, Clipboard, SearchIcon} from "lucide-react";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import RequireRole from "../../components/auth/RequireRole";
import {userRoles} from "../../config/userRoles";
import {useToast} from "../../contexts/ToastContext";
import {ActionTooltip} from "../../components/shared/tooltip";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {getDateByLocale} from "../../lib/utils";

export const AccountsScene: React.FC = () => {

    const {translate, language} = useLanguage();
    const {onOpen} = useModal();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const {add} = useToast();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search') || '';

    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [search, setSearch] = useState<string>(searchParam);
    const [accounts, setAccounts] = useState<AccountDataList>({totalCount: 0, list: []});

    const loadAccounts = () => {
        axiosPrivate.get(`/accounts/list?page=${currentPage}&size=${itemsPerPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}`).then((response) => {
            if (response.status === 200) {
                setAccounts(transformJsonToAccountDataListCount(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            add({message: translate('accounts.list.password-decrypted-and-copy'), type: 'success'});
        }).catch((error) => {
            console.log(error);
            add({message: translate('error.copy'), type: 'error'});
        });
    }

    const handleDecryptPassword = (item: AccountData) => {

        if (!item.decryptPassword) {
            const newAccounts = accounts.list.map((accountItem) => {
                if (accountItem.login === item.login) {
                    accountItem.loading = true;
                }
                return accountItem;
            });

            setAccounts({...accounts, list: newAccounts});

            axiosPrivate.get(`/accounts/decrypt?password=${item.password}`).then((response) => {
                if (response.status === 200 && response.data.data) {
                    const password = response.data.data;
                    const newAccounts = accounts.list.map((accountItem) => {
                        if (accountItem.login === item.login) {
                            accountItem.password = password;
                            accountItem.loading = false;
                            accountItem.decryptPassword = true;
                        }
                        return accountItem;
                    });
                    setAccounts({...accounts, list: newAccounts});
                    copyToClipboard(password);
                } else {
                    const newAccounts = accounts.list.map((accountItem) => {
                        if (accountItem.login === item.login) {
                            accountItem.loading = false;
                        }
                        return accountItem;
                    });
                    setAccounts({...accounts, list: newAccounts});
                }
            }).catch((error) => {
                const newAccounts = accounts.list.map((accountItem) => {
                    if (accountItem.login === item.login) {
                        accountItem.loading = false;
                    }
                    return accountItem;
                });
                setAccounts({...accounts, list: newAccounts});
                add({type: 'error', message: translate('accounts.list.password-decrypted-error')});
                console.log(error);
            });
        } else {
            copyToClipboard(item.password);
        }
    }

    function handleOpenDetailAccount(login: string) {
        navigate(`/accounts/detail?login=${login}`);
    }

    useEffect(() => {
        loadAccounts();
    }, [axiosPrivate, currentPage, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadAccounts();
    }, [axiosPrivate, search, sorted]);

    return (
        <div className="flex flex-col gap-4">
            <div className={"flex flex-row justify-between"}>
                <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('accounts.list.title-page')}</span>

                <Button disabled={loading}
                        onClick={() => onOpen('add-accounts')}>{translate('accounts.list.upload-accounts')}</Button>
            </div>

            <Card>
                <div className={"flex flex-col gap-4"}>
                    <div className={"flex flex-row justify-end"}>
                        <div className={"flex w-52"}>
                            <Input type={"search"}
                                   label={"Search"}
                                   value={search}
                                   onChange={(e) => setSearch(e.target.value)}
                                   icon={<SearchIcon size={20}/>}
                            />
                        </div>
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        <Table loading={loading} onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                            <TableHeader>
                                <TableColumnHeaderCell sortKey={"login"}>
                                    Login
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    Password
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"machine"}>
                                    Machine
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    Status
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    Recovery E-Mail
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    Locale
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"timeCreate"}>
                                    Created
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"timeUpdate"}>
                                    Updated
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('def.actions')}
                                </TableColumnHeaderCell>
                            </TableHeader>

                            <TableBody>
                                {accounts.list.map((item, index) => (
                                    <TableRow key={index} rowIndex={index}>
                                        <TableCell>
                                            {item.login}
                                        </TableCell>
                                        <TableCell>
                                            {item.loading ? <div className={"w-44 bg-gray/50 rounded-md h-4"}/> :
                                                <div className={"flex flex-row justify-between items-center"}>
                                                    <span className={"w-36 truncate"}>{item.password}</span>
                                                    <RequireRole allowedRoles={[userRoles.admin]}>
                                                        <ActionTooltip label={translate('accounts.list.decrypt-password-and-copy')}>
                                                            <div
                                                                onClick={() => {
                                                                    handleDecryptPassword(item)
                                                                }}
                                                                className={"flex flex-row p-1 bg-green/10 hover:bg-green/50 transition-all rounded-md cursor-pointer"}>
                                                                <Clipboard size={12}/>
                                                            </div>
                                                        </ActionTooltip>
                                                    </RequireRole>
                                                </div>}
                                        </TableCell>
                                        <TableCell>
                                            {item.machine === 'NULL' || !item.machine ?
                                                <span>NULL</span>
                                                : <a href={`/machines?search=${item.machine}`}
                                                     className={"hover:text-green transition-all"}
                                                     rel={"noreferrer"}
                                                     target={"_blank"}>{item.machine}</a>}
                                        </TableCell>
                                        <TableCell>
                                            {item.status}
                                        </TableCell>
                                        <TableCell>
                                            {item.recoveryEmail}
                                        </TableCell>
                                        <TableCell>
                                            <div className={"flex flex-row w-full justify-center gap-2"}>
                                                <img
                                                    className={"h-4 w-4"}
                                                    alt={item.locale.name}
                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.locale.country.toUpperCase()}.svg`}/>
                                                <span
                                                    className={"text-xs text-gray-dark dark:text-white font-bold"}>{item.locale.name}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {getDateByLocale(item.dateCreate, language)}
                                        </TableCell>
                                        <TableCell>
                                            {getDateByLocale(item.dateUpdate, language)}
                                        </TableCell>
                                        <TableCell>
                                            <div className={"flex w-full justify-center gap-2 p-2"}>
                                                <ActionTooltip label={translate('accounts.list.open-detail')}>
                                                    <ChevronRight
                                                        className={"cursor-pointer bg-green/40 hover:bg-green/80 rounded-md p-1"}
                                                        onClick={() => handleOpenDetailAccount(item.login)}
                                                        size={20}/>
                                                </ActionTooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                        <TablePagination totalItems={accounts.totalCount}
                                         currentPage={currentPage}
                                         itemsPerPage={itemsPerPage}
                                         onChangeItemsPerPage={setItemsPerPage} onChangePage={setCurrentPage}/>
                    </div>
                </div>
            </Card>
        </div>
    );
}
