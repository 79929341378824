import React, {ReactNode, useEffect, useRef, useState} from "react";
import {cn} from "../../lib/utils";
import {useToast} from "../../contexts/ToastContext";
import {AlertTriangle, Check, Info, X, XOctagon} from "lucide-react";

export type ToastPositionType =
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomRight"
    | "bottomCenter"
    | "bottomLeft";

export type RequiredToastProps = Required<ToastProps>;

export type ToastProps = {
    id: number;
    type?: "success" | "info" | "warning" | "error";
    message?: ReactNode;
    duration?: number;
    position?: ToastPositionType;
};

export const Toast: React.FC<ToastProps> = ({type = "info", message, id, duration = 3000}) => {
    let icon = getIcon(type)

    const wrapperRef = useRef<HTMLDivElement>(null);
    const {remove, position} = useToast();

    //auto dismiss
    const dismissRef = useRef<ReturnType<typeof setTimeout>>();
    useEffect(() => {
        if (duration) {
            dismissRef.current = setTimeout(() => {
                remove(id, wrapperRef);
            }, duration);
        }

        return () => {
            clearTimeout(dismissRef.current);
        };
    }, []);

    // progressBar
    const progressBarRef = useRef<ReturnType<typeof setInterval>>();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const complete = 100;

        if (duration) {
            progressBarRef.current = setInterval(() => {
                if (progress < complete) {
                    setProgress((prev) => prev + 1);
                } else {
                    return;
                }
            }, duration / complete);
        }

        return () => {
            clearInterval(progressBarRef.current);
        };
    }, []);
    return (
        <div
            style={{["--elm-translate" as any]: animationVariables[position]}}
            className={cn(
                wrapperClasses[type],
                "animate-toast-in",
                "flex justify-between items-center overflow-hidden rounded-md shadow-lg my-3 mx-2 relative"
            )}
            ref={wrapperRef}
            role={"alert"}
        >
            {!!duration && (
                <div className="absolute bottom-0 right-0 left-0 w-full h-1 bg-gray/50">
                     <span
                         className={`absolute left-0 ${bgSpan[type]} top-0 bottom-0 h-full`}
                         style={{width: `${progress}%`}}
                     />
                </div>
            )}

            {icon && (
                <div className={cn(iconClasses[type], "flex p-3")}>
                    <div className="inline-flex justify-center items-center w-6 h-6">
                        <span className="sr-only">{type} Icon</span>
                        {icon}
                    </div>
                </div>
            )}
            <div className="text-xs font-semibold flex-grow p-3">{message}</div>
            <button
                aria-label="Close"
                onClick={() => {
                    remove(id, wrapperRef);
                }}
                className={closeButtonClasses}
            >
                <span className="sr-only">Close</span>
                <X/>
            </button>
        </div>
    );
};

// RequiredToastProps prevents Type Error
export const wrapperClasses: Record<RequiredToastProps["type"], string> = {
    info: "bg-blue/30 text-blue",
    success: "bg-green/30 text-green",
    warning: "bg-orange/30 text-orange",
    error: "bg-red/30 text-red",
};
// RequiredToastProps prevents Type Error
export const iconClasses: Record<RequiredToastProps["type"], string> = {
    info: "dark:bg-blue/30 bg-blue/50 text-white dark:text-blue",
    success: "dark:bg-green/30 bg-green/50 text-white dark:text-green",
    warning: "dark:bg-orange/30 bg-orange/50 text-white dark:text-orange",
    error: "dark:bg-red/30 bg-red/50 text-white dark:text-red",
};

export const bgSpan: Record<RequiredToastProps["type"], string> = {
    info: "bg-blue",
    success: "bg-green",
    warning: "bg-orange",
    error: "bg-red",
};

export const closeButtonClasses =
    "w-4 h-4 mx-3 items-center justify-center text-gray/40 dark:text-gray/50 hover:text-gray/90 rounded-md focus:ring-2 focus:ring-gray/30 inline-flex dark:hover:text-white transition-all";

// return toast icon based on the toast type
export const getIcon = (type: RequiredToastProps["type"]) => {
    // This code is equivalent to Switch
    return {
        info: (
            <Info/>
        ),
        error: (
            <XOctagon/>
        ),
        success: (
            <Check/>
        ),
        warning: (
            <AlertTriangle/>
        ),
    }[type];
};

export const positionClasses: Record<ToastPositionType, string> = {
    topRight: "top-0 right-1",
    topCenter: "top-0 right-1/2 translate-x-1/2",
    topLeft: "top-0 left-1",
    bottomLeft: "bottom-0 left-1",
    bottomCenter: "bottom-0 right-1/2 translate-x-1/2",
    bottomRight: "bottom-0 right-1",
};

export const animationVariables: Record<ToastPositionType, string> = {
    topRight: "translateX(2000px)",
    topCenter: "translateY(-1300px)",
    topLeft: "translateX(-2000px)",
    bottomLeft: "translateX(-2000px)",
    bottomCenter: "translateY(1300px)",
    bottomRight: "translateX(2000px)",
};