import React, {useEffect} from "react";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {Separator} from "../../components/shared/separator";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {FaqData, transformJsonToApiKeyData, transformJsonToFaqDataList} from "../../lib/transform-json";
import {atomDark} from "react-syntax-highlighter/dist/cjs/styles/prism";
import {atomOneLight} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {useTheme} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {useToast} from "../../contexts/ToastContext";
import {ActionTooltip} from "../../components/shared/tooltip";

export const FaqBotApiScene: React.FC = () => {

    const {translate} = useLanguage();
    const {mode} = useTheme();

    const {apiKey, setApiKey} = useAuth();
    const {add} = useToast();

    const axiosPrivate = useAxiosPrivate();

    const [selected, setSelected] = React.useState<string | null>(null);

    const [faqData, setFaqData] = React.useState<FaqData[]>([]);

    const scrollTo = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "center"});
            setSelected(id);
        }
    }

    const handlerGenerateApiKey = () => {
        axiosPrivate.post(`/users/apiKey`).then((response) => {
            if (response.status === 200) {
                console.log(response.data.data);
                setApiKey(transformJsonToApiKeyData(response.data.data));
                add({message: translate('users.api-key-generated'), type: 'success'});
            }
        }).catch((error) => {
            console.log(error);
            add({message: translate('users.api-key-error'), type: 'error'});
        })
    }

    const handlerCopy = () => {
        navigator.clipboard.writeText(apiKey?.api_key!!).then(() => {
            add({message: translate('faq-api.api-key-copy'), type: 'success'});
        }).catch(() => {
            add({message: translate('error.copy'), type: 'error'});
        });
    }

    useEffect(() => {
        axiosPrivate.get("/api/v2/faq").then((response) => {
            setFaqData(transformJsonToFaqDataList(response.data.data));
        }).catch((error) => {
            console.log(error);
        });
    }, [axiosPrivate]);

    useEffect(() => {
        axiosPrivate.get("/users/apiKey").then((response) => {
            console.log(response.data.data);
            setApiKey(transformJsonToApiKeyData(response.data.data));
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <div className={"flex flex-col gap-4"}>
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('faq-api.title-page')}</span>
            <div className={"flex-grow grid grid-cols-[1fr,16rem] gap-2"}>
                <div className={"flex flex-col gap-2"}>
                    {faqData.map((data, index) => (
                        <Card id={data.type} key={data.type + '_' + index}
                              onClick={() => setSelected(selected === data.type ? null : data.type)} title={data.type}
                              isExpanded={selected !== data.type} controlled>
                            <div className={"flex flex-row gap-2"}>
                                <span className={"text-gray-dark dark:text-white font-bold"}>Url:</span>
                                <span
                                    className={"text-gray-dark dark:text-white"}>{data.host}</span>
                            </div>
                            <div className={"flex flex-row gap-2"}>
                                <span className={"text-gray-dark dark:text-white font-bold"}>Description:</span>
                                <span className={"text-gray-dark dark:text-white"}>{data.description}</span>
                            </div>
                            <div className={"flex flex-row gap-2"}>
                                <span className={"text-gray-dark dark:text-white font-bold"}>Method:</span>
                                <span className={"text-gray-dark dark:text-white"}>{data.method}</span>
                            </div>
                            <Separator orientation={"horizontal"}/>
                            <div className={"flex flex-col gap-2"}>

                                <span className={"text-gray-dark dark:text-white font-bold"}>Headers:</span>
                                <div className=" flex flex-col rounded-md w-full bg-gray/20 gap-2 p-4">
                                    <div className={"flex flex-row gap-2"}>
                                        <span className={"text-blue"}>Content-Type:</span>
                                        <span className={"text-green/50 dark:text-green"}>application/json</span>
                                    </div>
                                    <div className={"flex flex-row gap-2"}>
                                        <span className={"flex text-blue"}>X-Api-Key:</span>
                                        {apiKey?.api_key == null ? (
                                            <div className={"flex hover:font-bold transition-all cursor-pointer text-green/50 dark:text-green"}
                                                 onClick={handlerGenerateApiKey}>[Click to generate Api Key]</div>
                                        ) : (<div>
                                                <ActionTooltip label={translate('def.copy')}>
                                                    <span onClick={handlerCopy} className={"flex text-green/50 dark:text-green"}>{apiKey.api_key}</span>
                                                </ActionTooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <span className={"text-gray-dark dark:text-white font-bold"}>
                                    Body Request:
                                </span>

                                <div className="rounded-md w-full bg-gray/20">
                                    <SyntaxHighlighter
                                        language="json"
                                        style={mode === 'dark' ? atomDark : atomOneLight}
                                        customStyle={{
                                            margin: "0",
                                            padding: "1rem",
                                            backgroundColor: mode === 'dark' ? "transparent" : "",
                                            opacity: "1",
                                        }}>
                                        {JSON.stringify(data.requestBody, null, 2)}
                                    </SyntaxHighlighter>
                                </div>
                            </div>
                            {data.responsesBody && data.responsesBody.length > 0 && (
                                <>
                                    <Separator orientation={"horizontal"}/>
                                    <div className={"flex flex-col gap-2"}>
                                        <span className={"text-green dark:text-green-dark font-bold"}>Body Response:</span>
                                        {data.responsesBody.map((response, index) => (
                                            <div key={index + "_responseBody"} className="rounded-md w-full bg-gray/20">
                                                <SyntaxHighlighter
                                                    language="json"
                                                    style={mode === 'dark' ? atomDark : atomOneLight}
                                                    customStyle={{
                                                        margin: "0",
                                                        padding: "1rem",
                                                        backgroundColor: mode === 'dark' ? "transparent" : "",
                                                        opacity: "1",
                                                    }}>
                                                    {JSON.stringify(response, null, 2)}
                                                </SyntaxHighlighter>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                            {data.errorsBody && data.errorsBody.length > 0 && (
                                <>
                                    <Separator orientation={"horizontal"}/>
                                    <div className={"flex flex-col gap-2 mt-2"}>
                                        <span
                                            className={"text-red dark:text-red-dark font-bold"}>Errors Response:</span>
                                        {data.errorsBody.map((error, index) => (
                                            <div key={index + "_errorBody"}
                                                 className={"gap-1 rounded-md w-full bg-gray/20"}>
                                                <SyntaxHighlighter
                                                    language="json"
                                                    style={mode === 'dark' ? atomDark : atomOneLight}
                                                    customStyle={{
                                                        margin: "0",
                                                        padding: "1rem",
                                                        backgroundColor: mode === 'dark' ? "transparent" : "",
                                                        opacity: "1",
                                                    }}>{JSON.stringify(error, null, 2)}
                                                </SyntaxHighlighter>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </Card>
                    ))}
                </div>

                {faqData.length > 0 && (
                    <div
                        className={"sticky top-14 h-[400px] w-full rounded-md bg-white dark:bg-gray-dark p-2 gap-2 overflow-y-scroll"}>
                        <div className={"flex flex-col gap-2 w-full"}>
                            {faqData.map((faqData, index) => (
                                <span
                                    key={index}
                                    className={`p-2 cursor-pointer hover:bg-green/50 rounded-md transition-all font-bold text-gray-dark dark:text-white ${selected === faqData.type ? 'bg-green/50' : 'bg-green/10'}`}
                                    onClick={() => scrollTo(faqData.type)}>{faqData.type}</span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
