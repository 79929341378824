import React, {useState} from "react";
import {cn} from "../../lib/utils";
import {ChevronDown} from "lucide-react";

type CardProps = {
    id?: string;
    children: React.ReactNode;
    title?: string;
    className?: string;
    isExpanded?: boolean;
    onClick?: () => void;
    controlled?: boolean;
}

const Card: React.FC<CardProps> = ({
                                       id,
                                       children,
                                       title,
                                       className,
                                       isExpanded = false,
                                       onClick,
                                       controlled = false
                                   }) => {

    isExpanded = title ? isExpanded : false;

    const [expanded, setExpanded] = useState<boolean>(isExpanded);

    const isOpen = controlled ? isExpanded : expanded;

    const handleToggle = () => {
        if (controlled && onClick) {
            onClick();
        } else {
            setExpanded(!expanded);
        }
    };

    return (<div id={id} className={cn("flex flex-col w-full transition-all", className)}>
            {title &&
                <div
                    onClick={handleToggle}
                    className={`cursor-pointer bg-white dark:bg-gray-dark ${isOpen ? 'rounded-md border-transparent' : 'rounded-t-md border-gray-border dark:border-gray-dark-border'} flex flex-row justify-between w-full p-3 text-gray-dark font-bold dark:text-white border-b transition-all`}>
                    {title}
                    <ChevronDown className={`${isOpen ? '-rotate-90' : ''} transition-all`}/>
                </div>
            }
            <div className={cn("overflow-auto transition-all bg-white dark:bg-gray-dark", isOpen ? "max-h-0" : (title ? 'max-h-[10000px] rounded-b-md' : 'rounded-md'))}>
                <div
                    className={`flex flex-col gap-2 p-4`}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export {Card};
