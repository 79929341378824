import {useEffect, useState} from "react";

import {UserProfileModal} from "../components/modals/user-profile-modal";
import {ActiveMachinesModal} from "../components/modals/active-machines-modal";
import {AddAppModal} from "../components/modals/app/add-app-modal";
import {AddLocaleModal} from "../components/modals/add-locale-modal";
import {AddKeywordsModal} from "../components/modals/add-keywords-modal";
import {AddAccountsModal} from "../components/modals/add-accounts-modal";
import {AddProxyConfigModal} from "../components/modals/add-proxy-config-modal";
import {AddSmsCountryModal} from "../components/modals/add-sms-country-modal";
import {ConfirmModal} from "../components/modals/confirm-modal";
import {DetailDayModal} from "../components/modals/detail-day-modal";
import {AddServerModal} from "../components/modals/addServer/add-server-modal";
import {AddSmsServiceModal} from "../components/modals/add-sms-service-modal";
import {EditAppLocaleModal} from "../components/modals/app/edit-app-locale";

export const ModalProvider = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    return (
        <>
            <ConfirmModal/>
            <AddAppModal/>
            <EditAppLocaleModal/>
            <ActiveMachinesModal/>
            <UserProfileModal/>
            <AddLocaleModal/>
            <AddKeywordsModal/>
            <AddAccountsModal/>
            <AddProxyConfigModal/>
            <AddSmsServiceModal/>
            <AddSmsCountryModal/>
            <DetailDayModal/>
            <AddServerModal/>
        </>
    )
}
