import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../contexts/ToastContext";
import {Input} from "../../components/shared/Input";
import {Card} from "../../components/shared/card";
import {Separator} from "../../components/shared/separator";
import {Button} from "../../components/shared/button";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ChevronRight, SearchIcon} from "lucide-react";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {ServerData, ServerDataList, transformJsonToServerDataListCount} from "../../lib/transform-json";

const UpdateCenterScene: React.FC = () => {

    const {translate} = useLanguage();
    const navigate = useNavigate()
    const {add} = useToast();
    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [selected, setSelected] = useState<number[]>([]);
    const [serverData, setServerData] = useState<ServerDataList>({list: [], totalCount: 0});

    const [type, setType] = useState<string>('');
    const updateTypes = ['version_bot', 'link_update',];
    const [value, setValue] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleUpdate = () => {

        setError('');
        let hasError = false;

        if (!value || value === '') {
            setError(translate('value_required'));
            hasError = true;
        }

        if (selected.length === 0) {
            add({
                type: 'error',
                message: 'Select config'
            });
            hasError = true;
        }

        switch (type) {
            case 'version_bot':
                if (isNaN(Number(value))) {
                    hasError = true;
                    setError(translate('version_bot_invalid'));
                }
                break
            case 'link_update':
                if (!/^(http|https):\/\/[^ "]+$/.test(value)) {
                    hasError = true;
                    setError(translate('link_update_invalid'));
                }
                break
            default:
                add({
                    type: 'error',
                    message: 'Select type update'
                });
        }

        if (hasError) return;

        const ids = selected.map((id) => serverData.list[id].id);

        axiosPrivate.patch(`/servers/updateCenter`, {
            type: type,
            value: value,
            ids: ids
        }).then((response) => {
            if (response.status === 200) {
                setCurrentPage(1);
                add({
                    type: 'success',
                    message: 'Success update'
                });
                loadServers();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handlerOpenConfig = (data: ServerData) => {
        // navigate('/servers/update-config');
    }

    const loadServers = () => {
        axiosPrivate.get(`/servers/list?page=${currentPage}&search=${search}&size=${itemsPerPage}&sort=${sorted.key + ':' + sorted.ask}`).then((response) => {
            if (response.status === 200) {
                setServerData(transformJsonToServerDataListCount(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadServers();
    }, [axiosPrivate]);

    useEffect(() => {
        loadServers();
    }, [axiosPrivate, currentPage, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadServers();
    }, [axiosPrivate, search, sorted]);

    return (
        <div className={"flex flex-col gap-4"}>
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('update-center')}</span>
            <Card>
                <div className={"flex flex-row gap-2"}>
                    <div className={"flex w-52 items-center"}>
                        <Select
                            disabled={loading}
                            onValueChange={setType}
                        >

                            <SelectTrigger
                                className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none">
                                <SelectValue placeholder="Select type"/>
                            </SelectTrigger>

                            <SelectContent>
                                {Object.values(updateTypes).map((type) => (
                                    <SelectItem
                                        key={type}
                                        value={type}
                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                    >
                                        {type.replace('_', ' ').toUpperCase()}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className={"flex w-52"}>
                        <Input
                            type={"text"}
                            label={"Value"}
                            value={value}
                            disable={loading}
                            onChange={(e) => setValue(e.target.value)}
                            error={error}
                        />
                    </div>
                    <Button onClick={handleUpdate}>{translate('update')}</Button>
                </div>
                <Separator orientation={"horizontal"}/>
                <div className={"flex w-52"}>
                    <Input
                        type={"text"}
                        label={"Search"}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        icon={<SearchIcon size={20}/>}
                    />
                </div>
                <div className={"flex flex-col gap-4"}>
                    <Table selectable
                           loading={loading}
                           onSorted={(key, ask) => setSorted({key, ask})}
                           onSelect={setSelected}
                    >
                        <TableHeader>
                            <TableColumnHeaderCell sortKey={"name"}>
                                Name
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                Ip
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                Link Update
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                V. Bot
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                V. Market
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                V. Services
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell className={"w-12"}>
                                Open
                            </TableColumnHeaderCell>
                        </TableHeader>

                        <TableBody>
                            {serverData.list.map((item, index) => (
                                <TableRow key={index} rowIndex={index}>
                                    <TableCell>
                                        {item.name}
                                    </TableCell>
                                    <TableCell>
                                        {item.ip}
                                    </TableCell>
                                    <TableCell>
                                        {item.server_params.update_link}
                                    </TableCell>
                                    <TableCell>
                                        {item.server_params.version_bot}
                                    </TableCell>
                                    <TableCell>
                                        {item.server_params.version_market}
                                    </TableCell>
                                    <TableCell>
                                        {item.server_params.version_services}
                                    </TableCell>
                                    <TableCell>
                                        <div className={"flex w-full justify-center"}>
                                            <ChevronRight
                                                className={"cursor-pointer bg-green/40 hover:bg-green/80 rounded-md p-1"}
                                                size={20} onClick={() => handlerOpenConfig(item)}/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                    <TablePagination
                        totalItems={serverData.totalCount}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onChangeItemsPerPage={setItemsPerPage}
                        onChangePage={setCurrentPage}
                    />
                </div>
            </Card>
        </div>
    )
}

export default UpdateCenterScene
