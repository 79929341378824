import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {InstallMonitor} from "./install-monitor";
import {useEffect, useState} from "react";
import {CampaignData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Separator} from "../../components/shared/separator";
import {ApexDonutChart, ChartDonutData} from "../../components/shared/apex-chart";
import {ProgressLine} from "../../components/shared/progress-line";
import {cn} from "../../lib/utils";

export default function DashboardScene() {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const [campaignSelect, setCampaignSelect] = useState<CampaignData | null>(null);
    const [response, setResponse] = useState<any | null>(null);

    const loadStatistics = () => {
        axiosPrivate.get(`/statistic/campaign/keys?cuid=${campaignSelect?.id}`).then((response) => {
            if (response.status === 200 && response.data.data) {
                setResponse(response.data.data);
            }
        }).catch((error) => {
            console.error('Error fetching statistics:', error);
        });
    }

    useEffect(() => {
        if (campaignSelect) {
            loadStatistics();
            setResponse(null);
        }
    }, [campaignSelect]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('dashboard.title-page')}</span>
            <div className="flex flex-row">
                <InstallMonitor onChangeCampaign={(campaign) => setCampaignSelect(campaign)}/>
            </div>
            <div className="flex flex-row">
                <Card title={"Statistics by days"}>
                    {response && Object.keys(response).map((key) => {
                            const typeKey = key.split("$$")[0];
                            const newKey = key.split("$$")[1];

                            return (
                                <>
                                    <div key={key} className={cn("flex flex-row gap-10 items-center")}>
                                        <div className={"flex flex-row gap-2"}>
                                            <span
                                                className={"text-lg font-bold text-gray"}>
                                                {typeKey}
                                            </span>

                                            {newKey && newKey !== "" && (
                                                <span
                                                    className={"text-lg font-bold text-gray-dark dark:text-white"}>
                                                    {newKey}
                                                </span>
                                            )}
                                        </div>
                                        <div className={"grid lg:grid-cols-7 md:grid-cols-3 sm:grid-cols-1 items-center"}>
                                            {Object.entries(response[key]).map(([k, v]) => {
                                                const data = v as {
                                                    params: {
                                                        success: number,
                                                        not_found: number,
                                                        prev_install: number,
                                                        total: number,
                                                    }
                                                };

                                                const day = campaignSelect?.days.find((day) => day.day.toString() === k);

                                                let classNameStatus = "";

                                                if (day) {
                                                    day.status === "FINISHED" ? classNameStatus = "bg-green/10" : day.status === "IN_PROGRESS" ? classNameStatus = "bg-orange/10" : classNameStatus = "";
                                                }

                                                return v ? (
                                                    <div key={k + '_day_' + key}
                                                         className={cn("flex flex-col gap-1 p-1 border border-gray rounded-md m-1 items-center", classNameStatus)}>
                                                        <span className={"text-gray-dark dark:text-white"}>Day {k}</span>
                                                        <span className={"text-xs text-orange"}>Not Found: {data.params.not_found}</span>
                                                        <span className={"text-xs text-gray-dark dark:text-gray"}>Prev. Install: {data.params.prev_install}</span>
                                                        <span className={"text-xs text-green"}>Success: {data.params.success}/{data.params.total}</span>
                                                        <span
                                                            className={"text-gray-dark dark:text-white"}>Progress {(data.params.success / data.params.total * 100).toFixed(2)}%</span>
                                                        <ProgressLine progress={data.params.success / data.params.total * 100}/>
                                                    </div>
                                                ) : (
                                                    <div key={"not_found_day"} className={"flex flex-col gap-1 p-1 border border-gray rounded-md m-1 items-center"}>
                                                        <span className={"text-red-500"}>Data not available</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <Separator orientation={"horizontal"} className={"m-1"}/>
                                </>
                            )
                        }
                    )}
                </Card>
            </div>
        </div>
    );
}
