import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {Input} from "../../components/shared/Input";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {
    AppData,
    ErrorData,
    KeywordData,
    KeywordDataList,
    LocaleData,
    transformJsonToAppDataList,
    transformJsonToKeywordDataList,
    transformJsonToKeywordDataListCount,
    transformJsonToLocaleDataList
} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Button} from "../../components/shared/button";
import {Play, Plus, RefreshCw, SearchIcon, Star, Trash} from "lucide-react";
import {TabsContainer} from "../../components/shared/tabs-container";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {useToast} from "../../contexts/ToastContext";
import {useModal} from "../../hooks/use-modal-store";
import {AxiosError} from "axios";
import {useLocation} from "react-router";
import {useWebSocket} from "../../contexts/WebSocketContext";
import {IMessage} from "@stomp/stompjs";
import {useAuth} from "../../contexts/AuthContext";
import {ApexLineChart, ChartLineData, ItemLineData} from "../../components/shared/apex-chart";
import {addDays} from "date-fns";
import DateRangePickerPopover from "../../components/shared/date-range-picker";

export default function KeywordsScene() {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const auidParam = searchParams.get('auid') || '';
    const searchIdLocale = Number.parseInt(searchParams.get('locale_id') || '0', 0);

    const {isOpen, onOpen, data} = useModal();
    const {add} = useToast();
    const {getConnection, getConnectionStatus} = useWebSocket();
    const auth = useAuth();

    const [loading, setLoading] = useState({
        init: true,
        history: false,
        keys: false
    });

    const [responseApp, setResponseApp] = useState<AppData[]>([]);

    const [selectedApp, setSelectedApp] = useState<AppData | null>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [search, setSearch] = useState<string>('');
    const [keyData, setKeyData] = useState<KeywordDataList>({totalCount: 0, list: []});

    const [selectedLocale, setSelectedLocale] = useState<LocaleData | null>(null);
    const [locales, setLocales] = useState<LocaleData[]>([]);

    const tabs = ["ALL", "FAVORITE"];
    const [tab, setTab] = useState<string>(tabs[0]);

    const [historyData, setHistoryData] = useState<ChartLineData>({categories: [], series: []});
    const startDate = addDays(new Date(), -7);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const [rangeDate, setRangeDate] = useState<{ startDate: Date, endDate: Date }>({
        startDate: startDate,
        endDate: endDate
    });

    const openAddAppModal = () => {
        onOpen(
            'add-app',
            {},
            {
                onAddedApp: (appData) => {
                    setResponseApp(prev => {
                        if (!prev.some(app => app.auid === appData.auid)) {
                            return [...prev, appData];
                        }
                        return prev;
                    });

                    setSelectedApp(appData);
                }
            }
        );
    }

    const handleDelete = (item: KeywordData) => {
        axiosPrivate.delete(`/keywords/delete?kuid=${item.id}`)
            .then((response) => {
                if (response.status === 200) {
                    add({
                        message: translate('keyword-deleted'),
                        type: 'success'
                    });
                    setCurrentPage(1);
                    loadKeys();
                }
            }).catch((error) => {
            console.log(error);
        });
    }

    const handleFavorite = (index: number, item: KeywordData) => {
        axiosPrivate.patch(`/keywords/favorite?kuid=${item.id}`)
            .then((response) => {
                if (response.status === 200) {
                    setKeyData(prevState => ({
                        ...prevState,
                        list: prevState.list.map((keyword, i) => {
                            if (i === index) {
                                return {
                                    ...keyword,
                                    favorite: !keyword.favorite
                                }
                            }
                            return keyword;
                        })
                    }));

                    loadHistoryPosition();
                }
            }).catch((error) => {
            console.log(error);
        });
    }

    const handleCheckPosition = (item: KeywordData) => {

        if (item.status === 'IN_CHECK') {
            add({message: translate('keywords.keyword-in-check'), type: 'info'});
            return;
        }

        axiosPrivate.post(`/keywords/check?kuid=${item.id}`)
            .then((response) => {
                if (response.status === 200) {
                    add({message: translate('keywords.success-start-checking') + ' ' + item.keyword, type: 'info'});
                }
            }).catch((error) => {

            const axiosError = error as AxiosError<ErrorData>

            if (axiosError.response) {
                if (axiosError.response.status === 400) {
                    add({message: axiosError.response.data?.error_message, type: 'error'});
                } else {
                    add({message: translate(`error.error-refresh-keyword`) + ` - ${item.keyword}`, type: 'error'});
                }
            } else {
                add({message: translate(`error.error-refresh-keyword `) + ` - ${item.keyword}`, type: 'error'});
            }
        });
    }

    const connectionStatus = getConnectionStatus('keywordsStatus');
    const onMessageReceived = (message: IMessage) => {

        if (message.body === '' || message.body === '[]') {
            return;
        }

        const dataKeywords = transformJsonToKeywordDataList(JSON.parse(message.body));

        if (dataKeywords.length === 0) {
            return;
        }

        setKeyData(prevState => ({
            ...prevState,
            list: prevState.list.map((keyword) => {
                const newKeyword = dataKeywords.find((newKeyword) => newKeyword.id === keyword.id);
                if (newKeyword) {
                    setHistoryData(prevHistoryData => {
                        const updatedSeries = prevHistoryData.series.map((item) => {
                            if (item.name === newKeyword.keyword) {
                                const lastDataPoint = item.data[item.data.length - 1];
                                const newY = newKeyword.position === 0 ? null : newKeyword.position;

                                if (lastDataPoint !== newY) {
                                    return {
                                        ...item,
                                        data: [
                                            ...item.data.slice(0, -1),
                                            newY,
                                        ],
                                    };
                                }
                            }
                            return item;
                        });

                        return {
                            ...prevHistoryData,
                            series: updatedSeries,
                        };
                    });

                    return newKeyword;
                }
                return keyword;
            }),
        }));

    };

    useEffect(() => {
        const connection = getConnection('keywordsStatus');

        if (auth.user?.username && connection?.connected) {
            const subscription = connection.subscribe(`/user/${auth.user.username}/queue/keywordsStatus`, onMessageReceived);
            connection.publish({destination: `/ws/keywordsStatusStart`, body: JSON.stringify({start: true})});

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [auth.user?.username, getConnection, connectionStatus]);

    const loadKeys = () => {
        if (selectedLocale !== null && selectedApp !== null) {
            axiosPrivate.get(`/keywords/list?auid=${selectedApp.auid}&locale_id=${selectedLocale.id}&page=${currentPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}&size=${itemsPerPage}&favorite=${tab === 'FAVORITE'}`)
                .then((response) => {
                    if (response.status === 200 && response.data.data) {
                        setKeyData(transformJsonToKeywordDataListCount(response.data.data));
                        setLoading({
                            ...loading,
                            keys: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading({
                        ...loading,
                        keys: false
                    });
                });
        }
    }

    const loadHistoryPosition = () => {
        if (selectedApp === null) {
            return;
        }

        setLoading(
            {
                ...loading,
                history: true
            }
        );

        axiosPrivate.post(`/keywords/history`,
            {
                auid: selectedApp.auid,
                locale_id: selectedLocale?.id,
                start_date: rangeDate.startDate,
                end_date: rangeDate.endDate,
            })
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    if (Object.keys(response.data.data.data_monitoring).length > 0) {
                        let responseData = response.data.data;

                        let dataMonitoring = responseData.data_monitoring;
                        const result: ChartLineData = {categories: [], series: []};

                        result.categories = responseData.time_labels.map((item: any) => timestampToDateStr(item));

                        Object.keys(dataMonitoring).forEach((key) => {
                            let md: ItemLineData = {name: key, data: []};
                            result.categories.forEach((item, _) => {
                                let add = false;
                                dataMonitoring[key].forEach((v: any) => {
                                    if (item === timestampToDateStr(v.time_check)) {
                                        add = true;
                                        if (v.value !== 0) {
                                            md.data.push(v.value);
                                        } else if (md.data[md.data.length - 1]) {
                                            md.data.push(md.data[md.data.length - 1]);
                                        } else {
                                            md.data.push(null);
                                        }
                                    }
                                });

                                if (!add) {
                                    if (md.data[md.data.length - 1]) {
                                        md.data.push(md.data[md.data.length - 1]);
                                    } else {
                                        md.data.push(null);
                                    }
                                }
                            });
                            result.series.push(md);
                        });

                        setHistoryData(result);
                    } else {
                        setHistoryData({categories: [], series: []});
                    }
                }

                setLoading(
                    {
                        ...loading,
                        history: false
                    }
                );
            }).catch((error) => {
            setHistoryData({categories: [], series: []});
            console.log(error);
            setLoading(
                {
                    ...loading,
                    history: false
                }
            );
        });
    }

    function timestampToDateStr(timestamp: number): string {
        const date = new Date(timestamp);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    function dateStrToTimestamp(dateStr: string): number {
        const [day, month, year] = dateStr.split('.').map(Number);
        const date = new Date(year, month - 1, day);
        return date.getTime();
    }

    useEffect(() => {
        loadHistoryPosition();
    }, [axiosPrivate, rangeDate, selectedApp, selectedLocale]);

    useEffect(() => {
        if (selectedApp === null) return;
        setLoading({
            ...loading,
            keys: true,
            history: true
        });
        if (selectedApp.locale !== null) {
            setSelectedLocale(locales.find(locale => locale.id === selectedApp.locale?.id) || null);
        } else {
            loadKeys();
        }
    }, [selectedApp]);

    useEffect(() => {
        if (selectedApp === null) return;
        loadKeys();
        setCurrentPage(1);
    }, [tab, selectedLocale]);

    useEffect(() => {
        if (selectedApp === null) return;
        loadKeys();
    }, [search, sorted, itemsPerPage, currentPage]);

    useEffect(() => {
        if (!isOpen && data.success && selectedApp !== null) {
            loadKeys();
        }
    }, [isOpen, data]);

    const loadApps = () => {
        axiosPrivate.get('/apps/list')
            .then((response) => {
                if (response.status === 200) {
                    let appData = transformJsonToAppDataList(response.data.data).filter(app => app.status === 'ACTIVE');
                    setResponseApp(appData);
                    let appSearch = appData.find(app => app.auid === auidParam);
                    setSelectedApp(appSearch || null);
                }
                setLoading({
                    ...loading,
                    init: false
                });
            }).catch((error) => {
            setLoading({
                ...loading,
                init: false
            });
            console.log(error);
        });
    }

    const loadData = () => {
        setLoading({
            ...loading,
            init: true
        });

        axiosPrivate.get('/locales/list').then((response) => {
            if (response.status === 200) {
                let localeData = transformJsonToLocaleDataList(response.data.data)
                    .filter(locale => locale.type === 'STANDARD')
                    .sort((a, _) => a.country === 'us' ? -1 : 1);
                setLocales(localeData);
                setSelectedLocale(localeData[0]);
                loadApps();
            } else {
                add({type: 'error', message: 'Error load task types'});
            }
        }).catch((error) => {
            console.log(error);
            add({type: 'error', message: 'Error load task types'});
        });
    }

    //Load firs on open page
    useEffect(() => {
        loadData();

        return () => {

        }
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('keywords.title-page')}</span>

            <Card>
                <div className={"flex flex-row justify-between"}>
                    <div className={"flex flex-row gap-4"}>
                        <div className={"flex flex-col gap-1"}>
                            <div className={"flex flex-row items-center gap-4"}>
                                <div className={"w-96"}>
                                    <Select
                                        disabled={loading.init}
                                        value={selectedApp?.auid}
                                        onValueChange={(value) => setSelectedApp(responseApp.find(app => app.auid === value) || null)}
                                    >
                                        <SelectTrigger
                                            className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                            <SelectValue placeholder="Select Application"/>
                                        </SelectTrigger>

                                        <SelectContent className={"flex flex-col"}>
                                            <div className={"h-fit h-max-52 overflow-scroll"}>
                                                {Object.values(responseApp).map((app) => (
                                                    <SelectItem
                                                        key={app.auid}
                                                        value={app.auid}
                                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                    >
                                                        <div className={"flex flex-row gap-2"}>
                                                            <Avatar className={`h-8 w-8 transition-all`}>
                                                                <AvatarFallback delayMs={500}>
                                                                    I
                                                                </AvatarFallback>
                                                                <AvatarImage
                                                                    src={app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                        app.details[selectedLocale?.country + '_' + selectedLocale?.language].image_src :
                                                                        app.details[app.locale?.country + '_' + app.locale?.language] ?
                                                                            app.details[app.locale?.country + '_' + app.locale?.language].image_src :
                                                                            ''}
                                                                />
                                                            </Avatar>
                                                            <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>
                                                                {app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].name :
                                                                    app.details[app.locale?.country + '_' + app.locale?.language] ?
                                                                        app.details[app.locale?.country + '_' + app.locale?.language].name :
                                                                        'Undefined'}
                                                            </span>
                                                                <span
                                                                    className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                {app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].publisher :
                                                                    app.details[app.locale?.country + '_' + app.locale?.language] ?
                                                                        app.details[app.locale?.country + '_' + app.locale?.language].publisher :
                                                                        'Undefined'}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                            </div>
                                        </SelectContent>
                                    </Select>
                                    <span className={"text-xs text-gray-dark dark:text-white"}>
                                    {translate('keywords.select-app')}
                                        <span className={"text-xs cursor-pointer text-green/50 hover:text-green hover:font-bold transition-all"}
                                              onClick={() => openAddAppModal()}> {translate('keywords.add-app-here')}
                                        </span>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-1"}>
                            <div className={"flex flex-row items-center gap-4"}>
                                <div className={"w-52"}>
                                    <Select
                                        disabled={loading.init}
                                        value={selectedLocale?.country + '_' + selectedLocale?.language}
                                        onValueChange={(value) => setSelectedLocale(locales.find(locale => locale.country + '_' + locale.language === value)!!)}
                                    >
                                        <SelectTrigger
                                            className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                            <SelectValue placeholder="Select Application"/>
                                        </SelectTrigger>

                                        <SelectContent className={"flex flex-col"}>
                                            <div className={"h-fit h-max-52 overflow-scroll"}>
                                                {Object.values(locales).map((locale) => (
                                                    <SelectItem
                                                        key={locale.name}
                                                        value={locale.country + '_' + locale.language}
                                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                    >
                                                        <div className={"flex flex-row gap-2"}>
                                                            <img
                                                                className={"h-4 w-6 rounded-md overflow-hidden"}
                                                                alt={locale.country.toUpperCase()}
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                                            <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                                            </div>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                            </div>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex justify-center items-center"}>
                        <Button disabled={selectedApp === null} onClick={() => onOpen('add-keywords', {appData: selectedApp!!, localeData: selectedLocale!!})}>
                            <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                                <Plus size={18}/>
                                <span className={"text-xs"}>{translate('keywords.add-keywords')}</span>
                            </div>
                        </Button>
                    </div>
                </div>
            </Card>

            {selectedApp !== null && (
                <div className={"flex flex-col gap-2"}>
                    <Card>
                        <div>
                            <div className={"flex flex-col gap-6"}>
                                <div className={"flex flex-col gap-1"}>
                                    <div className={"flex flex-row items-center gap-4"}>
                                        <DateRangePickerPopover
                                            initDates={rangeDate}
                                            onApply={(start, end) => setRangeDate({startDate: start, endDate: end})}
                                        />
                                    </div>
                                </div>
                                <ApexLineChart data={historyData} loading={loading.history} invertY={true} type={'line'} min={1}/>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={"flex flex-col gap-4"}>
                            <div className={"flex flex-row justify-end"}>
                                <div className={"flex w-52"}>
                                    <Input type={"text"}
                                           label={translate('def.search')}
                                           value={search}
                                           disable={loading.keys}
                                           onChange={(e) => setSearch(e.target.value)}
                                           icon={<SearchIcon size={20}/>}
                                    />
                                </div>
                            </div>

                            <div className={"flex w-full"}>
                                <TabsContainer classNameDivChildren={"flex flex-col gap-4 p-2"} tabs={tabs}
                                               onTabChange={setTab}>
                                    <Table loading={loading.keys}
                                           onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                                        <TableHeader>
                                            <TableColumnHeaderCell className={"w-12"}/>
                                            <TableColumnHeaderCell sortKey={"keyword"}>
                                                {translate('keywords.keyword')}
                                            </TableColumnHeaderCell>
                                            <TableColumnHeaderCell>
                                                {translate('def.locale')}
                                            </TableColumnHeaderCell>
                                            <TableColumnHeaderCell sortKey={"position"}>
                                                {translate('def.position')}
                                            </TableColumnHeaderCell>
                                            <TableColumnHeaderCell className={"w-24"}/>
                                        </TableHeader>

                                        <TableBody>
                                            {keyData.list.map((item, index) => (
                                                <TableRow key={index} rowIndex={index}>
                                                    <TableCell>
                                                        <ActionTooltip label={translate('keywords.save-favorite')}>
                                                            <div
                                                                className={`flex w-full justify-center ${item.favorite ? 'text-orange' : ''}`}
                                                                onClick={() => handleFavorite(index, item)}
                                                            >
                                                                <Star size={16}/>
                                                            </div>
                                                        </ActionTooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.keyword}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={"flex flex-row w-full justify-center gap-2"}>
                                                            <img
                                                                className={"h-4 w-4"}
                                                                alt={item.locale.name}
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.locale.country.toUpperCase()}.svg`}/>
                                                            <span
                                                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{item.locale.name}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            className={"flex flex-row w-full justify-center gap-2 text-xs text-gray-dark dark:text-white"}>
                                                            <span className={item.position === 0 && item.status !== 'IN_CHECK' ? 'text-red' : ''}>
                                                                {item.position === 0 ? (item.status === 'IN_CHECK' ? translate('keywords.processing-check-keyword') : 'Out') : item.position}
                                                            </span>
                                                            {item.status === 'CHECKED' && item.position_diff !== 0 && (
                                                                <span
                                                                    className={`${item.position_diff > 0 ? 'text-green' : 'text-red'}`}>({item.position_diff > 0 ? '+' : ''}{item.position_diff})
                                                                </span>
                                                            )}
                                                            <RefreshCw
                                                                className={`cursor-pointer ${item.status === 'IN_CHECK' ? 'animate-[spin_1.5s_infinite]' : ''}`}
                                                                onClick={() => handleCheckPosition(item)} size={14}/>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={"flex flex-row justify-center gap-2 p-2"}>
                                                            <ActionTooltip label={translate('keywords.open-play-market')}>
                                                                <a
                                                                    rel={"noreferrer"}
                                                                    target={"_blank"}
                                                                    href={`https://play.google.com/store/search?c=apps&q=${item.keyword}&gl=${item.locale.country.toLowerCase()}&hl=${item.locale.language.toLowerCase()}-${item.locale.country.toUpperCase()}`}>
                                                                    <div className={"relative rounded-md p-1"}>
                                                                        <div
                                                                            className="absolute inset-0 bg-gradient-to-tr from-play-red to-play-blue rounded-md"></div>
                                                                        <div
                                                                            className="relative z-10 flex items-center justify-center w-full h-full">
                                                                            <Play size={14}
                                                                                  className="text-play-yellow"/>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </ActionTooltip>
                                                            <ActionTooltip label={translate('def.delete')}>
                                                                <div
                                                                    className={"p-1 text-gray-dark dark:text-white rounded-md bg-red/30 hover:bg-red/50 cursor-pointer transition-all"}
                                                                    onClick={() => handleDelete(item)}
                                                                >
                                                                    <Trash size={14}/>
                                                                </div>
                                                            </ActionTooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                    <TablePagination totalItems={keyData.totalCount}
                                                     currentPage={currentPage}
                                                     itemsPerPage={itemsPerPage}
                                                     onChangeItemsPerPage={setItemsPerPage}
                                                     onChangePage={setCurrentPage}/>
                                </TabsContainer>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </div>
    )
}
