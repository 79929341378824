import React, {useEffect, useRef, useState} from 'react';
import {useClickAway} from 'react-use';
import {DateRange} from 'react-date-range';
import {addDays} from "date-fns";
import {enUS, ru, uk} from 'date-fns/locale';
import {CalendarRange} from "lucide-react";
import {useLanguage} from "../../contexts/LanguageContext";

type DateRangePickerPopoverProps = {
    disable?: boolean;
    initDates?: { startDate: Date, endDate: Date };
    onApply?: (startDate: Date, endDate: Date) => void;
    isTimeSelect?: boolean;
}

const DateRangePickerPopover: React.FC<DateRangePickerPopoverProps> = ({initDates, onApply, isTimeSelect, disable = false}) => {

    const {language, translate} = useLanguage();
    const [locale, setLocale] = useState(enUS);

    const [isOpen, setIsOpen] = useState(false);
    const [isSelectingTime, setIsSelectingTime] = useState(false);
    const [isHiding, setIsHiding] = useState(false);

    const buttonRef = useRef(null);
    const isHidingRef = useRef(isHiding);

    const [selectionRange, setSelectionRange] = useState({
        startDate: initDates?.startDate || addDays(new Date(), -7),
        endDate: initDates?.endDate || new Date(),
        key: 'selection'
    });

    const [startHour, setStartHour] = useState(0);
    const [endHour, setEndHour] = useState(23);

    const handleSelect = (ranges: any) => {
        setSelectionRange(ranges.selection);
    };

    useEffect(() => {
        isHidingRef.current = isHiding;
    }, [isHiding]);

    useEffect(() => {
        switch (language) {
            case 'en':
                setLocale(enUS);
                break;
            case 'ua':
                setLocale(uk);
                break;
            case 'ru':
                setLocale(ru);
                break;
            default:
                setLocale(enUS);
        }
    }, [language]);

    const applyDates = () => {
        const startDate = new Date(selectionRange.startDate);
        const endDate = new Date(selectionRange.endDate);

        startDate.setHours(startHour, 0, 0, 0);
        endDate.setHours(endHour, 59, 59, 999);

        onApply?.(startDate, endDate);
        setIsOpen(false);
        setIsSelectingTime(false);
    }

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setIsSelectingTime(false);
    };

    const dateToStringLocale = (date: Date, hour: number) => {
        const dateOptions: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'short', year: 'numeric'};
        return `${date.toLocaleDateString(language === 'ua' ? 'uk' : language, dateOptions)} ${isTimeSelect ? (' ' + hour + ':00') : ''}`;
    }

    useClickAway(buttonRef, () => {
        if (isOpen) {
            setIsHiding(true);

            setTimeout(() => {
                if (isHidingRef.current) {
                    setIsHiding(false);
                    setIsOpen(false);
                    setIsSelectingTime(false);
                }
            }, 300);
        } else {
            setIsOpen(true);
            setIsSelectingTime(false);
        }
    });

    return (
        <div className="relative inline-block">
            <div onClick={() => {
                if (!disable) {
                    toggleOpen();
                }
            }}
                 className={`flex text-xs flex-row p-2 border border-gray/30 rounded-md ${disable ? 'cursor-no-drop' : 'cursor-pointer hover:bg-green/20'}  w-fit text-gray-dark dark:text-white items-center justify-center transition-all`}>
                <CalendarRange size={16}/>
                <span className="ml-2">
                    {dateToStringLocale(disable && initDates ? initDates.startDate : selectionRange.startDate, startHour)} --- {dateToStringLocale(disable && initDates ? initDates.endDate : selectionRange.endDate, endHour)}
                </span>
            </div>
            {isOpen && (
                <div
                    ref={buttonRef}
                    className={`absolute mt-2 left-0 z-50 bg-white/20 dark:bg-white/20 shadow-lg rounded p-4 backdrop-blur ${isHiding ? 'animate-fade-out' : 'animate-fade-in'}`}>
                    <div className="flex flex-col gap-3">
                        {!isSelectingTime ? (
                            // Выбор диапазона дат
                            <DateRange
                                ranges={[selectionRange]}
                                locale={locale}
                                maxDate={new Date()}
                                minDate={addDays(new Date(), -365)}
                                onChange={handleSelect}
                                moveRangeOnFirstSelection={false}
                                rangeColors={['#039e46']}
                            />
                        ) : (
                            // Выбор времени для начала и конца
                            <div className="flex flex-row gap-10 justify-center">
                                <div className={"flex flex-col items-end gap-1 p-1"}>
                                    <label className={"text-xs text-gray-dark dark:text-white"}>{translate('Start Time')}</label>
                                    <select
                                        value={startHour}
                                        onChange={(e) => setStartHour(parseInt(e.target.value))}
                                        className="p-1 border rounded-md"
                                    >
                                        {Array.from({length: 24}, (_, hour) => (
                                            <option key={hour} value={hour}>{hour}:00</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={"flex flex-col items-start gap-1 p-1"}>
                                    <label className={"text-xs text-gray-dark dark:text-white"}>{translate('End Time')}</label>
                                    <select
                                        value={endHour}
                                        onChange={(e) => setEndHour(parseInt(e.target.value))}
                                        className="p-1 border rounded-md"
                                    >
                                        {Array.from({length: 24}, (_, hour) => (
                                            <option key={hour} value={hour}>{hour}:00</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}

                        <div className="flex flex-row gap-1 justify-end">
                            {!isSelectingTime && isTimeSelect ? (
                                <span
                                    onClick={() => setIsSelectingTime(true)}
                                    className="p-2 bg-green/70 hover:bg-green transition-all text-white rounded-md cursor-pointer text-center">
                                    {translate('Select Time')}
                                </span>
                            ) : (
                                <span
                                    onClick={applyDates}
                                    className="p-2 bg-green/70 hover:bg-green transition-all text-white rounded-md cursor-pointer text-center">
                                    {translate('def.apply')}
                                </span>
                            )}
                            <span onClick={toggleOpen}
                                  className="p-2 bg-red/70 hover:bg-red transition-all text-white rounded-md cursor-pointer text-center">
                                {translate('def.cancel')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePickerPopover;
