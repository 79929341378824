import React, {useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {useLanguage} from "../../contexts/LanguageContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {getDateByLocale} from "../../lib/utils";
import DateRangePickerPopover from "../../components/shared/date-range-picker";
import {addDays} from "date-fns";
import {ApexLineChart, ChartLineData, ItemLineData} from "../../components/shared/apex-chart";

export const InstallMonitorAdmin: React.FC = () => {

    const {translate, language} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState({
        init: true,
        statistic: false,
    });

    const chartTypes = ["SUCCESS", "VERIFY", "BAN"];

    const startDate = addDays(new Date(), 0);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const [rangeDate, setRangeDate] = useState<{ startDate: Date, endDate: Date }>({
        startDate: startDate,
        endDate: endDate
    });

    const [statisticData, setStatisticData] = useState<ChartLineData>({categories: [], series: []});
    const [lastUpdateTime, setLastUpdateTime] = useState<string | null>('');

    const loadStatistics = (force: boolean = false) => {
        setLoading({
            ...loading,
            statistic: true
        });

        setStatisticData({categories: [], series: []});

        axiosPrivate.post(`/statistic/admin/servers`, {
            start_date: rangeDate.startDate.toISOString(),
            end_date: rangeDate.endDate.toISOString(),
            force_update: force
        }).then((response) => {
            if (response.status === 200 && response.data.data) {
                if (Object.keys(response.data.data.data_monitoring).length > 0) {
                    let responseData = response.data.data;

                    let dataMonitoring = responseData.data_monitoring;
                    const result: ChartLineData = {categories: [], series: []};

                    result.categories = responseData.time_labels.map((item: any) => millisecondsToDateStrWithHour(item));

                    let totalSumData: number[] = Array(result.categories.length).fill(0);

                    Object.keys(dataMonitoring).forEach((key) => {
                        let md: ItemLineData = {name: key, data: []};
                        result.categories.forEach((item, index) => {
                            let valueForCurrentTime = 0;
                            dataMonitoring[key].forEach((v: any) => {
                                if (item === millisecondsToDateStrWithHour(v.time_check)) {
                                    valueForCurrentTime = v.value;
                                }
                            });
                            md.data.push(valueForCurrentTime);
                            totalSumData[index] += valueForCurrentTime;
                        });
                        result.series.push(md);
                    });

                    result.series.push({name: 'Total', data: totalSumData});

                    setLastUpdateTime(responseData.time_update);
                    setStatisticData(result);
                } else {
                    setStatisticData({categories: [], series: []});
                }

                setLoading({
                    ...loading,
                    statistic: false
                });
            } else {
                setLoading({
                    ...loading,
                    statistic: false
                });
            }
        }).catch((error) => {
            console.log(error);
            setLoading({
                ...loading,
                statistic: false
            });
        });
    }

    function millisecondsToDateStrWithHour(milliseconds: number): string {
        const date = new Date(milliseconds);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');

        return `${day}.${month} ${hours}:00`;
    }

    const handleRefresh = () => {
        loadStatistics(true);
    }

    useEffect(() => {
        loadStatistics();
    }, [rangeDate]);

    return (
        <Card>
            <div className={"grid grid-cols-3 w-full items-center"}>
                <div className={"flex justify-start"}>
                    <span className={"font-bold text-white"}>{translate('dashboard.install-monitor')}</span>
                </div>

                <div className={"flex justify-center"}>
                    <div className={"w-52"}>
                        <Select
                            disabled={loading.init || loading.statistic}
                            onValueChange={(value) => console.log(value)}
                            defaultValue={chartTypes[0]}
                        >

                            <SelectTrigger
                                className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none">
                                <SelectValue placeholder="Chart type"/>
                            </SelectTrigger>

                            <SelectContent>
                                {Object.values(chartTypes).map((type) => (
                                    <SelectItem
                                        key={type}
                                        value={type}
                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                    >
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div
                    onClick={handleRefresh}
                    className={"cursor-pointer flex flex-row items-center gap-1 justify-end"}>
                    {loading.statistic ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={"Last update time"}>
                            <span className={"text-xs text-gray/50"}>{getDateByLocale(lastUpdateTime, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.statistic ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <DateRangePickerPopover
                initDates={rangeDate}
                onApply={(start, end) => setRangeDate({startDate: start, endDate: end})}
            />

            <ApexLineChart data={statisticData} loading={loading.statistic} min={0}/>
        </Card>
    )
}
