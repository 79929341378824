import React, {useEffect, useRef, useState} from 'react';
import {userRoles} from "../../config/userRoles";

import RequireRole from "../auth/RequireRole";
import {useLanguage} from "../../contexts/LanguageContext";
import {useTheme} from "../../contexts/ThemeContext";

import {Bell, LanguagesIcon, MenuSquare, Moon, Sun, Trash} from 'lucide-react';
import {useSidebar} from "../../contexts/SidebarContext";
import {ActionTooltip} from "../shared/tooltip";
import {IMessage} from "@stomp/stompjs";
import {NotificationData, transformJsonToNotificationDataList} from "../../lib/transform-json";
import {useAuth} from "../../contexts/AuthContext";
import {useWebSocket} from "../../contexts/WebSocketContext";
import {Separator} from "../shared/separator";
import {getDateByLocale} from "../../lib/utils";

const TopBar: React.FC = () => {

    const sidebar = useSidebar();

    const {translate} = useLanguage();

    return (
        <div
            className="z-10 sticky top-0 flex h-12 bg-white/90 dark:bg-gray-dark/80 w-full justify-between items-center backdrop-blur border-b border-gray-border dark:border-gray-dark-border">
            <div className="p-2 cursor-pointer hover:bg-green/20 rounded-md ml-1 lg:invisible xl:invisible"
                 onClick={sidebar.toggleSidebar}>
                <MenuSquare className="text-gray dark:text-white"/>
            </div>
            <div
                className="gap-2 transition-all items-center">
                <div className="flex items-center space-x-1 mr-2">
                    <LanguageSelect/>
                    <ActionTooltip
                        side="bottom"
                        align="center"
                        label={translate('topbar.theme-change')}>
                        <ThemeSelect/>
                    </ActionTooltip>
                    <NotificationCenter/>
                </div>
            </div>
        </div>
    );
}

export default TopBar;

type LanguageItemProps = {
    iconSrc: string;
    label: string;
    languageIso: string;
}

const LanguageSelect: React.FC = () => {

    const [showLanguages, setShowLanguages] = useState(false);
    const language = useLanguage();
    const languageRef = useRef<HTMLDivElement | null>(null);

    const [isHiding, setIsHiding] = useState(false);

    const isHidingRef = useRef(isHiding);

    useEffect(() => {
        isHidingRef.current = isHiding;
    }, [isHiding]);

    const handleShowLanguages = () => {
        if (showLanguages) {
            setIsHiding(true);

            setTimeout(() => {
                if (isHidingRef.current) {
                    setIsHiding(false);
                    setShowLanguages(false);
                }
            }, 300);
        } else {
            setShowLanguages(true);
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (languageRef.current && !languageRef.current.contains(event.target as Node) && showLanguages) {
                handleShowLanguages();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showLanguages]);

    return (
        <div
            className="cursor-pointer p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"
            onClick={handleShowLanguages}>
            <div className="absolute mt-4 h-3 w-3">
                <img src={`/images/${language.language}.png`} alt="Current language"/>
            </div>
            <LanguagesIcon/>
            {showLanguages && (
                <div
                    ref={languageRef}
                    className={`absolute left-0 top-full mt-2 w-full min-w-max bg-white border border-gray-border dark:border-gray-dark-border dark:bg-gray-dark rounded-md divide-y divide-gray-border dark:divide-gray-dark-border overflow-hidden transition-all ${isHiding ? 'animate-fade-out' : 'animate-fade-in'}`}>
                    <LanguageItem iconSrc={'/images/en.png'} label={'EN'} languageIso={'en'}/>
                    <LanguageItem iconSrc={'/images/ua.png'} label={'UA'} languageIso={'ua'}/>
                    <LanguageItem iconSrc={'/images/ru.png'} label={'RU'} languageIso={'ru'}/>
                </div>
            )}
        </div>
    )
}

const LanguageItem: React.FC<LanguageItemProps> = ({iconSrc, label, languageIso}) => {

    const {setLanguage} = useLanguage();

    return (
        <div className="cursor-pointer flex items-center space-x-1 p-2 hover:bg-green/20"
             onClick={() => setLanguage(languageIso)}>
            <img className="h-4 w-4" alt="" src={iconSrc}/>
            <span className="text-sm text-gray-dark dark:text-white">{label}</span>
        </div>
    );
}

const ThemeSelect: React.FC = () => {

    const {toggleTheme, mode} = useTheme();

    return (
        <div className="cursor-pointer p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white transition-all"
             onClick={toggleTheme}>
            {mode === 'dark' ? <Moon className="transition-all"/> :
                <Sun className="transition-all"/>}
        </div>
    )
}

const NotificationCenter: React.FC = () => {
    const auth = useAuth();

    const {translate} = useLanguage();

    const [isAlert, setAlert] = useState(false);
    const [notificationsInbox, setNotificationsInbox] = useState<NotificationData[]>([]);
    const [notificationsArchive, setNotificationsArchive] = useState<NotificationData[]>([]);
    const [activeTab, setActiveTab] = useState<'inbox' | 'archive'>('inbox');

    const {getConnection, getConnectionStatus} = useWebSocket();

    const connectionStatus = getConnectionStatus('notification');

    const onMessageReceived = (message: IMessage) => {
        const dataNotifications = transformJsonToNotificationDataList(JSON.parse(message.body));

        setNotificationsInbox(prev => {
            const newKeys = dataNotifications.filter(dn => !prev.some(pk => pk.id === dn.id && dn.status === 'NEW'));
            return [...prev, ...newKeys];
        });

        setNotificationsArchive(prev => {
            const newKeys = dataNotifications.filter(dn => !prev.some(pk => pk.id === dn.id && dn.status !== 'NEW'));
            return [...prev, ...newKeys];
        })

        setAlert(dataNotifications.some(notification => notification.status === 'NEW'));
    };

    const dismissNotification = (id: string) => {
        const connection = getConnection('notification');
        if (connection && connection.connected) {
            connection.publish({destination: `/ws/notificationDismiss`, body: JSON.stringify({id})});
        }
    };

    const deleteNotification = (id: string) => {
        const connection = getConnection('notification');
        if (connection && connection.connected) {
            connection.publish({destination: `/ws/notificationDelete`, body: JSON.stringify({id})});
        }
    };

    useEffect(() => {
        const connection = getConnection('notification');

        if (auth.user?.username && connection?.connected) {
            const subscription = connection.subscribe(`/user/${auth.user.username}/queue/notificationCenter`, onMessageReceived);
            connection.publish({destination: `/ws/notificationsStart`, body: JSON.stringify({start: true})});

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [auth.user?.username, getConnection, connectionStatus]);

    const [showNotifications, setShowNotifications] = useState(false);
    const notificationRef = useRef<HTMLDivElement | null>(null);

    const [isHiding, setIsHiding] = useState(false);

    const isHidingRef = useRef(isHiding);

    useEffect(() => {
        isHidingRef.current = isHiding;
    }, [isHiding]);

    const handleShowNotifications = () => {
        if (showNotifications) {
            setIsHiding(true);

            setTimeout(() => {
                if (isHidingRef.current) {
                    setIsHiding(false);
                    setShowNotifications(false);
                }
            }, 300);
        } else {
            setShowNotifications(true);
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (notificationRef.current && !notificationRef.current.contains(event.target as Node) && showNotifications) {
                handleShowNotifications();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showNotifications]);

    return (
        <RequireRole allowedRoles={[userRoles.user]}>
            <div>
                <div
                    className="cursor-pointer p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white transition-all"
                    onClick={handleShowNotifications}>
                    <Bell className={`${isAlert ? 'animate-[shake-notification_0.6s_infinite]' : ''}`}/>
                    {isAlert && (
                        <span
                            className="absolute right-4 top-3 inline-flex items-center justify-center p-1 text-[2px] leading-none bg-red rounded-full"></span>
                    )}
                </div>
                {showNotifications && (
                    <div
                        ref={notificationRef}
                        className={`absolute right-2 top-full mt-2 min-w-[300px] max-h-[500px] bg-white dark:bg-gray-dark border border-gray-border dark:border-gray-dark-border rounded-md divide-y divide-gray-border dark:divide-gray-dark-border transition-all ${isHiding ? 'animate-fade-out' : 'animate-fade-in'}`}>

                        <div className={"z-50 flex flex-col"}>
                            <div className="flex m-2 justify-center items-center">
                                <div className="cursor-pointer flex flex-col w-52 items-center"
                                     onClick={() => setActiveTab('inbox')}>
                                    <button
                                        className={`w-full hover:text-green hover:font-bold ${activeTab === 'inbox' ? 'text-green font-bold' : 'text-gray border-b-0 '} border-green/70 transition-all`}
                                    >
                                        {translate('notifications.inbox_tab')}
                                    </button>
                                    <div
                                        className={`rounded h-1 bg-green/70 dark:bg-green-dark/70 ${activeTab === 'inbox' ? 'w-full' : 'w-0 '} transition-all`}/>
                                </div>
                                <div className="cursor-pointer flex flex-col w-52 items-center"
                                     onClick={() => setActiveTab('archive')}>
                                    <button
                                        className={`w-full hover:text-green hover:font-bold ${activeTab === 'archive' ? 'text-green font-bold' : 'text-gray'} transition-all`}
                                    >
                                        {translate('notifications.archive_tab')}
                                    </button>
                                    <div
                                        className={`rounded h-1 bg-green/70 dark:bg-green-dark/70 ${activeTab === 'archive' ? 'w-full' : 'w-0 '} transition-all`}/>
                                </div>
                            </div>

                            <Separator/>

                            <div className={"flex max-h-[450px] overflow-scroll"}>
                                {activeTab === 'archive' ? (
                                    <div className="flex-row w-full">
                                        {notificationsArchive.length === 0 ? (
                                            <div
                                                className="flex p-2 text-gray-dark dark:text-white justify-center">
                                                <span className="text-xl">{translate('notifications.no_notifications')}</span>
                                            </div>
                                        ) : (
                                            notificationsArchive.map((notification, index) => (
                                                <NotificationItem key={index} data={notification}
                                                                  onDelete={deleteNotification}/>
                                            ))
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex-row w-full">
                                        {(notificationsInbox.length === 0) ? (
                                            <div
                                                className="flex p-2 text-gray-dark dark:text-white justify-center">
                                                <span className="text-xl">{translate('notifications.no_notifications')}</span>
                                            </div>
                                        ) : (
                                            notificationsInbox.map((notification, index) => (
                                                <NotificationItem key={index} data={notification}
                                                                  onClick={dismissNotification}/>
                                            ))
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </RequireRole>
    );
}

type NotificationItemProps = {
    data: NotificationData;
    onClick?: (id: string) => void;
    onDelete?: (id: string) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({data, onClick, onDelete}) => {

    const {language, translate} = useLanguage();

    return (
        <div
            className="flex flex-col gap-2 m-2 p-2 border border-gray rounded-md cursor-pointer hover:bg-green/20 transition-all text-gray-dark dark:text-white"
            onClick={() => onClick && onClick(data.id)}>

            <span className="text-xs text-gray">{getDateByLocale(data.time, language)}</span>
            <span className="text-xl font-bold">{data.title}</span>
            <span className="text-gray-dark dark:text-white">{data.body}</span>
            {onDelete && (
                <div className={"flex flex-row justify-end gap-2"}>
                    <ActionTooltip label={translate('delete')}>
                        <div
                            className={"p-1 text-gray-dark dark:text-white rounded-md bg-red/30 hover:bg-red/50 cursor-pointer"}
                            onClick={() => onDelete(data.id)}
                        >
                            <Trash size={14}/>
                        </div>
                    </ActionTooltip>
                </div>
            )}
        </div>
    );
}
