import React, {useEffect, useState} from "react";
import {ServerData} from "../../../lib/transform-json";
import {Input} from "../../shared/Input";
import {Checkbox} from "../../shared/checkbox";
import {useLanguage} from "../../../contexts/LanguageContext";
import {Separator} from "../../shared/separator";

type DedikServerType = {
    serverData: ServerData;
    setForm: (form: ServerData) => void;
    loading: boolean;
    error: Record<string, { value: string | undefined }>;
}

export const DedikServer: React.FC<DedikServerType> = ({serverData, setForm, loading, error}) => {

    const {translate} = useLanguage();

    const [disks, setDisks] = useState<{ checked: boolean, name: string }[]>([
        {checked: true, name: 'home'},
        {checked: false, name: 'md2'},
        {checked: false, name: 'md3'},
        {checked: false, name: 'md4'},
    ]);

    useEffect(() => {
        const updatedDisks = disks
            .filter(disk => disk.checked)
            .map(disk => ({
                id: null,
                name: disk.name,
                count_machine: 0,
                start_machine: 0
            }));

        setForm(({
            ...serverData,
            disks: updatedDisks
        }));
    }, [disks]);

    return (
        <div className={"flex flex-col gap-1"}>
            <div className={"grid grid-cols-2 flex-row gap-2"}>
                <div className="grid">
                    <div className={"flex flex-col gap-3"}>
                        <Input
                            label={"Name Server"}
                            type={"text"}
                            value={serverData.name}
                            onChange={(e) => setForm({...serverData, name: e.target.value})}
                            error={error.name?.value}
                            disable={loading}
                        />
                        <Input
                            label={"IP Server"}
                            type={"text"}
                            value={serverData.ip}
                            onChange={(e) => setForm({...serverData, ip: e.target.value})}
                            error={error.ip?.value}
                            disable={loading}
                        />
                        <div className="flex flex-col gap-1">
                            <span className="text-xs font-bold text-gray-dark dark:text-white">Reboot every day?</span>
                            <Checkbox
                                label={translate('def.yes-no')}
                                defaultChecked={serverData.server_params.reboot || false}
                                onCheck={(checked) => setForm({
                                    ...serverData,
                                    server_params: {
                                        ...serverData.server_params,
                                        reboot: checked
                                    }
                                })}
                                disable={loading}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="text-xs font-bold text-gray-dark dark:text-white">Disks</span>
                            <div className="flex flex-row gap-2">
                                {disks.map((disk, index) => (
                                    <Checkbox
                                        key={index}
                                        label={disk.name}
                                        defaultChecked={disk.checked}
                                        disable={loading}
                                        onCheck={(checked) => {
                                            const newDisks = disks.map((item, idx) =>
                                                idx === index ? {...item, checked: checked} : item
                                            );
                                            setDisks(newDisks);
                                        }}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className={"flex flex-col gap-3"}>
                        {disks.some(disk => disk.checked) && (
                            <div>
                                <div className="grid grid-cols-4 gap-2">
                                    {serverData.disks.map((disk, index) => (
                                        <div key={index}>
                                            <div className="flex flex-col gap-2">
                                            <span
                                                className="text-xs font-bold mb-1 ml-1 text-gray-dark dark:text-white">
                                                {disk.name}
                                            </span>
                                                <Input
                                                    label={"Count"}
                                                    value={disk.count_machine || 0}
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...serverData,
                                                            disks: serverData.disks.map(d =>
                                                                d.name === disk.name
                                                                    ? {...d, count_machine: Number(e.target.value)}
                                                                    : d
                                                            )
                                                        })}
                                                    disable={loading}
                                                    type={"number"}/>
                                                <Input
                                                    label={"Max Start"}
                                                    value={disk.start_machine || 0}
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...serverData,
                                                            disks: serverData.disks.map(d =>
                                                                d.name === disk.name
                                                                    ? {...d, start_machine: Number(e.target.value)}
                                                                    : d
                                                            )
                                                        })}
                                                    disable={loading}
                                                    type={"number"}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <Separator className="mt-4" orientation={"horizontal"}/>
                            </div>
                        )}

                        <Input
                            label={"Task In Queue"}
                            value={serverData.server_params.task_in_queue || 3}
                            onChange={(e) =>
                                setForm({
                                    ...serverData,
                                    server_params: {
                                        ...serverData.server_params,
                                        task_in_queue: Number(e.target.value)
                                    }
                                })}
                            disable={loading}
                            type={"number"}/>
                        <Input
                            label={"Time to Bootloop (sec)"}
                            value={serverData.server_params.time_to_bootloop || 900}
                            onChange={(e) =>
                                setForm({
                                    ...serverData,
                                    server_params: {
                                        ...serverData.server_params, time_to_bootloop: Number(e.target.value)
                                    }
                                })}
                            disable={loading}
                            type={"number"}/>
                        <Input
                            label={"Time between start (sec)"}
                            value={serverData.server_params.time_between_start || 30}
                            onChange={(e) => setForm({
                                ...serverData,
                                server_params: {
                                    ...serverData.server_params, time_between_start: Number(e.target.value)
                                }
                            })}
                            disable={loading}
                            type={"number"}/>
                    </div>
                </div>
            </div>
            <Separator orientation={"horizontal"} className={"mt-2 mb-2"}/>
            <div className="flex flex-col gap-3">
                <Input
                    label={'Version Bot'}
                    value={serverData.server_params.version_bot || 0}
                    onChange={(e) => setForm({
                        ...serverData,
                        server_params: {
                            ...serverData.server_params, version_bot: Number(e.target.value)
                        }
                    })}
                    disable={loading}
                    type={"number"}/>
                <Input
                    label={'Version Services'}
                    value={serverData.server_params.version_services}
                    onChange={(e) => setForm({
                        ...serverData,
                        server_params: {
                            ...serverData.server_params, version_services: e.target.value
                        }
                    })}
                    disable={loading}
                    type={"text"}/>
                <Input
                    label={'Version Market'}
                    value={serverData.server_params.version_market}
                    onChange={(e) => setForm({
                        ...serverData,
                        server_params: {
                            ...serverData.server_params, version_market: e.target.value
                        }
                    })}
                    disable={loading}
                    type={"text"}/>
                <Input
                    label={'Link Update'}
                    value={serverData.server_params.update_link}
                    onChange={(e) => setForm({
                        ...serverData,
                        server_params: {
                            ...serverData.server_params, update_link: e.target.value
                        }
                    })}
                    disable={loading}
                    error={error.update_link?.value}
                    type={"url"}/>
            </div>
        </div>
    );
}
